.BV {

  .header {
    margin-bottom: 25px;
    padding-left: 16px;
    padding-right: 16px;

    h1 {
      font-size: 2rem;
    }
  }
  
  .month-picker-box{
      margin-top: 20px;
  }
 
  .BVtable {
    border-radius: 5px;
    padding-left: 6px;
    padding-right: 6px;

    @media (min-width: 481px) {
      padding-left: 16px;
      padding-right: 16px;
    }

    .thead-dark {
      font-size: 13px;

      @media (min-width: 768px) {
        font-size: 15px;
      }
    }

    .table-body {
      font-size: 11px;

      @media (min-width: 768px) {
        font-size: 13px;
      }
    }
  }

}

// to be used for bv, coins and also family member
.bv-box{
  display: flex;
  justify-content: left;
  text-align: center;
  font-size: 12px;
  width:calc(100% - 10px);
  p{
    font-weight: 600;
  }
  .bv-box-1{
    width:120px;
    height:85px;
    background-color: white;
    padding-top: 10px;
    margin-right:10px ;
    border: 2px solid $second-color;
    border-radius: 10px;

    h6{
      font-size: 14px;
      font-weight: 600;
      color: #778da9;
    }

  }
  .bv-box-2{
    width:120px;
    height:85px;
    background-color: white;
    padding-top: 10px;
    border: 2px solid $second-color;
    border-radius: 10px;

    h6{
      font-size: 14px;
      font-weight: 600;

      color: #778da9;
    }
  }
}