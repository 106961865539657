.terms-and-conditions {

  text-align: left;
  // justify-content: center;
  // align-items: center;

  .title {
    text-align: center;
    justify-content: center;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 5vh;
    color: #778da9;
  }

  .content-wrapper {
    padding: 0 10vw 0;

    @media (max-width: 950px) {
      padding: 0 5vw 0;
    }

    h1 {
      color: #778da9;
      font-size: 25px;
      margin-top: 50px;

      @media (max-width: 950px) {
        font-size: 20px;

      }
    }

    p {
      color: #555555;
      font-size: 13px;

      @media (max-width: 950px) {
        font-size: 12px;

      }
    }
  }
}