.user-family {

  .header {
    margin-bottom: 25px;
    padding-left: 16px;
    padding-right: 16px;

    h1 {
      font-size: 2rem;
    }
  }

  .tree {
    width: 100%;
    border-radius: 10px;
    padding: 10px;
    box-shadow:#cae9f5 0px 0px 2px 0px,#cae9f5 0px 0px 2px 0px;    
    margin-bottom: 10px;
    
    ul {
      li {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .show-family-btn{
    position: relative;
    width: 150px;
    height: 40px;
    font-weight: 700;
    font-size: 14px;

    background-color: white;
    color:black;
    border-radius: 25px;
    border: solid black 2px;

    &:hover{
      background-color:black;
      color: white;
      border: solid black 2px;
    }
    &:disabled{
      background-color:#eeeeee;
      color: #333333;
      border: solid black 2px;
    }
  }
  .member-info{
    margin: 0;
  }
  .eduCenter-table{
    margin-bottom: 40px !important;
  }
  .edu-key{
    font-weight: 600;
    font-size: 13px;
    @media (max-width: 481px) {
      font-size: 11px;
    }
  }
  .edu-value{
    font-weight: 500;
    font-size: 13px;
    color: $third-color;
    @media (max-width: 481px) {
      font-size: 11px;
    }
  }

}