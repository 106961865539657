.gallery {
  .header {
    margin-bottom: 25px;
    padding-left: 16px;
    padding-right: 16px;

    h1 {
      font-size: 2rem;
    }
  }

  .gallery-content {
    // text-align: center;

    .a-holder {
      padding: 10px 10px 20px 10px;

      P {
        font-weight: 600;
        font-size: 14px;
      }

      a {
        color: $third-color;
        background-color: $first-color;
        font-weight: 600;
        font-size: 12px;

        &:hover {
          color: #67A3D9;
          text-decoration: none;
        }
      }
    }
  }
}