@import "./dashboard-sidebar.scss";
@import "./forms-table.scss";
@import "./orders-history-dashboard.scss";
@import "./content.scss";
@import "./voucher.scss";

/***** Public elements *******/
.dashboard-headline {
  font-size: 2.5rem;
  font-weight: 700;
  color: grey;
  margin-bottom: 15px;
  margin-top: 135px;

  @media (min-width: 992px) {
    margin-top: 150px;

  }

  @media (max-width: 575px) {
    margin-top: 0px;

  }

}

// ---------------per page scss-----------

.per-page-col {
  position: relative;
  height: 50px;

  .per-page {
    position: absolute;
    margin-bottom: 10px;
    right: 0;
    font-weight: 500;

    .custom-select {
      position: absolute;
      width: 170px;
      height:30px;
      right: 75px;
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      border:1px solid $second-color;
      box-shadow: none;
      @media (max-width: 480px) {
        right: 80px;
      }
      @media (max-width: 776px) {
        width:20vw;
      }
    }

    span {
      margin-top: 5px;
      position: absolute;
      right: 0;
      font-size: 14px;
      width: 85px;
      text-align: right;
      @media (max-width: 480px) {
        right: 5px;

      }
    }
  }
}

// ---------------calendar scss-----------

.calendarWrap {
  label {
    margin-right: 10px;
    margin-bottom: 25px;
    font-weight: 600;

  }

  .inputBox {
    font-weight: 600;
    color:$third-color;
    border: 2px solid $second-color;
    text-align: center;
    width: 210px;
    font-size: 14px;
    outline: none;
    border-radius: 4px;
  }
  .calender-btn{
    position: relative;
    top: -1px;
    border: 2px solid $second-color;
    height:27px;
    padding: 2px 15px;
    border-radius: 4px;
    color: black;
    font-size: 13px;
    font-weight: 700;
    margin-left: 5px;
    display: inline-block;
    cursor: pointer;
    background-color: $first-color;
    &:hover {
      text-decoration: none;
    } 
  }
 
}

//-------------------------------------------

.dashboard-spinner {
  text-align: center;
  margin-top: 30px;
}

// for invoice and indicator export
.download-btn{
  border: 1px solid $third-color;
  padding: 8px 30px;
  height:39px;
  color: $third-color;
  font-size: 13px;
  font-weight: 700;
  margin-left: 5px;
  background-color: lavender;
  &:hover {
    text-decoration: none;
  }
  
}


//------------for commissions-------------------------------

.commissions{
  align-items: left;
}
.item-name{
  font-weight: 700;
  display: inline;
  font-size: 12px;
}
.item-value{
  font-weight: 500;
  color: gray;
  display: inline;
  font-size: 12px;
}