/* @import url('https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap'); */

Body{
  margin: 0;

  /* The main reason for using "system" fonts is performance. Fonts are typically one of the 
  largest/heaviest resources loaded on a website. If we can use a font already available on the user’s 
  machine, we can completely eliminate the need to fetch this resource, making load times noticeably faster. */
  
  /* https://bitsofco.de/the-new-system-font-stack/ */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  /* we can import the font from google fonts above and use here, here means the font applies to whole website  */
  /* font-family: 'Cormorant Upright', serif; */
  /* font-family: 'Open Sans', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
