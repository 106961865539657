.choose-address {
  padding-bottom: 10px;
  overflow: auto;

  .instruction {
    padding-left: 30px;
  }

  h3 {
    color: $third-color;
    font-size: 15px;
    font-weight: 600;
  }

  Button {
    font-size: 14px;

    @media (max-width: 776px) {
      font-size: 12px;
    }
  }

  .warning {
    margin-top: 50px;
    width: 80%;
  }

  .address-list {
    margin-top: 30px;
    padding: 0 5vh 5vh;
    @media (max-width: 481px) {
      padding: 0 3vh 3vh;
    }
    Button {
      font-size: 14px;

      @media (max-width: 776px) {
        font-size: 12px;
      }
    }


    label {
      cursor: pointer;
      padding: 15px 25px;
      border: 2px solid $second-color;
      border-radius: 5px;
      display: block;
      margin-bottom: 25px;
      line-height: 1.3rem;

      a {
        font-size: 14px;
        line-height: 1.8rem;
      }
    }

    .recipent {
      font-weight: 700;
      font-size: 14px;
      margin: 5px 0 8px;
      color: #333333;
    }

    .address,
    .phone {
      line-height: 20px;
      font-size: 12px;
      display: block;
      color: #333333;
      @media (max-width: 776px) {
        font-size: 11px;
      }
    }

    .edit {
      color: #0066c0;
      font-weight: 500;
      font-size: 12px;
    }
  }
}