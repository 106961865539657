.user-voucher{

  .header {
    margin-bottom: 25px;
    padding-left: 16px;
    padding-right: 16px;

    h1 {
      font-size: 2rem;
    }
  }

}