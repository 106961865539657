.rating {
  h3 {
    color: $third-color;
  }

  .rating-div {
    @media (min-width: 900px) {
      padding: 0 15vw;
    }

    .rating-summary {
      padding: 0;
      border-bottom: 2px solid $second-color;

      .rating-header {
        display: flex;
      }

      .review_info {
        font-size: 40px;
        height: 170px;
        display: flex !important;
        @media (max-width: 480px) {
          font-size: 33px;
    
        }

        .average-rating {
          height: 80%;
          display: inline-block;
          width: 20%;
          position: relative;
          top: 35px;

          p {
            font-size: 12px;
            font-weight: 600;
            color: $third-color;
            @media (max-width: 480px) {
              font-size: 11px;
        
            }
          
          }
        }

        .component {
          width: 100%;

          .bargraph {
            font-size: 20px;
            width: 100%;

            .top_star {
              display: flex;
              width: 100%;

              .star_num {
                font-size: 12px;
                font-weight: 600;
                display: flex;
                align-items: center;
              }

              .star_item {
                display: flex;
                width: 100%;

                .top {
                  background: #a0c15a;
                  height: 20px !important;
                  border-left: 1px solid #a0c15a;
                  border-radius: 10px;
                  position: relative;
                  top: 5px;
                }

                .midtop {
                  background: #add633;
                  height: 20px !important;
                  border-left: 1px solid #add633;
                  border-radius: 10px;
                  position: relative;
                  top: 5px;
                }

                .neutral {
                  height: 20px !important;
                  background: #ffd935;
                  border-left: 1px solid #ffd935;
                  border-radius: 10px;
                  position: relative;
                  top: 5px;
                }

                .midbottom {
                  height: 20px !important;
                  background: #ffb235;
                  border-left: 1px solid #ffb235;
                  border-radius: 10px;
                  position: relative;
                  top: 5px;
                }

                .bottom {
                  height: 20px !important;
                  background: #ff8c5a;
                  border-left: 1px solid #ff8c5a;
                  border-radius: 10px;
                  position: relative;
                  top: 5px;
                }

                .top,
                .midtop,
                .neutral,
                .midbottom,
                .bottom {
                  display: flex;
                }

                .number {
                width:15px;
                font-size: 18px;
                font-weight: 500;
                display: flex;
                align-items: center;
                @media (max-width: 480px) {
                  font-size: 16px;
            
                }
                }

                .star {
                width:25px;
                
                }

              }
            }
          }
        }
      }
    }

    .to-rate {
      padding: 15px 0;

      .rating-star {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
        cursor: pointer;
      }

      .review-box {
        border: 2px solid $second-color ;
        margin-bottom: 15px;
        padding: 7px;

        .textarea {
          border: none;
          border-radius: 5;
          min-height: 80px;
          width: 100%;
          font-size: 13px;

          &:focus {
            outline: none;
          }
        }

        .picked-review {
          border: 1px solid $second-color;
          border-radius: 10px;
          background-color: $first-color;
          display: inline-block;
          font-weight: 600;
          font-size: 13px;
          padding: 0 10px;
          margin-right: 10px;
          position: relative;
          top: -3px;
          cursor: pointer;
        }
      }
    }

    .all-review-section {
      margin-top: 15px;
      .review-wrapper {
        border: 2px solid $second-color;

        .all-ratings-picked-review {
          color: $third-color;
          font-weight: 600;
          font-size: 13px;
          padding: 0 10px 0 0;
          display: inline-block;
        }

        p {
          font-size: 13px;
          margin: 0;
        }

        .all-ratings-date{
          color:gray;
          font-size: 11px;
          font-weight: 600;
        }
      }
      .load-more-outer{
        width: 100%;
        text-align: center;
      }
    }
  }
}