.breadcrumb {
  font-weight: 600;
  background-color: unset !important;

  .active {
    color:#195275 !important;
  }

  a {
    color: black !important;
  }
   
}
