.orders-history-dashboard {
  margin-bottom: 15px;

  .spinner {
    text-align: center;
    margin-top: 30px;
    width:50px;
    height: 50px;
  }

  .header {
    padding-left: 16px;
    font-size: 2.5rem;
    font-weight: 700;
    padding-bottom: 25px;

  }
}

.box-dashboard {
  justify-content: center;

  .single-member,
  .single-order{
    width: 100vw;
    margin: 0 15px 15px 15px;
    border: 2px solid $second-color;
    border-radius: 5px;
    border-bottom: none;

    @media (min-width: 481px) {
      width: 85vw;
    }
    @media (min-width: 992px) {
      width: 70vw;
    }

    .tracking-wrapper{
      width: 100vw;
      padding: 5px 0;
      display: flex;
      justify-content: center;
      .add-category-form-toship {
        width: 80vw;
        border: 2px solid $second-color;
        border-radius: 5px;
        padding: 10px 25px;


        @media (min-width: 480px) {
          width: 40vw;
          font-size: 14px;

        }
      
        .form-group {
          margin-bottom: 10px;
          
          label {
            font-weight: 600;
            font-size: 0.9rem;
          }
          .form-control{
            border: 2px solid $second-color;

            font-size: 0.7rem;
          }
        }
      }
    }

    .single-order-heading {
      overflow: hidden;
      padding: 5px 0;
      width: 100%;
      background-color: $first-color;
      margin: 0;
      border-radius: 5px 5px 0 0;
      border-bottom: 2px solid $second-color;
      font-size: 0.8rem;
      font-weight: 600;
      color: #333333;

      @media (max-width: 480px) {
        font-size: 0.65rem;
      }
    }

    /***** Single item *****/
    .single-order-item {
      width: 100%;
      padding: 10px 0 5px;
      margin: 0;
      border-bottom: 2px solid $second-color;

      .wrapper {
        text-align: center;

        img {
          width: 40vh;
          border: none;
          box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);


          @media (min-width: 480px) {
            width: 170px;
            font-size: 14px;
          }
        }

        a {
          font-size: 19px;
          color: #555;
          text-decoration: underline;
          font-weight: 600;

          &:hover {
            cursor: pointer;
            color: #777777;
          }
        }
      }

      .productName {
        margin-top: 30px;
        font-size: 12px;
        text-decoration: none;
        font-weight: 700;
        color: $third-color;
        @media (max-width: 480px) {
          margin-top: 20px;
        }
      }

      .quantity,
      .price,
      .courier,
      .tracking {
        font-weight: 700;
        font-size: 0.8rem;
        @media (max-width: 480px) {
          font-size: 0.65rem;
        }

        span {
          font-weight: 500;
          color: #555;
        }
      }
    }
  }


}