.categories {
  margin-bottom: 35px;
 
  .category-wrapper{
    .function-item-wrapper{
      padding:0 1px;
      .function-item {
        background-color:white;
        border: solid $second-color 2px;
        width: 100%;
        padding: 0;
        margin-bottom: 1px;
  
        &:hover {
          a {
            // margin-left: 1px;
            background-color: $first-color;
          }
        }
    
        .dropdown-item {
          font-size: 14px;
          padding-left: 10px;
        }
    
      }
    }
   
  }
  

}