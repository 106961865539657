.settings-sidebar {
  margin-left: -15px;
  background-color: #555555;
  width:108%;

  li {
    padding: 10px 0;
    margin-left: -40px;
    margin-right:-30px;
    border: 1px solid #fff;
    list-style: none;


    &:hover {
      i {
        margin-left: 12px;
      }
      .icon{
        margin-left: 12px;

      }
    }
    .icon{
      margin-left: 8px;
      color:black;
      width: 20px;

    }
    a{
      display:inline-block;
      width: 80%;
      color: black;
      text-decoration: none;
      margin-left: 10px;
    }
    i {
      width: 18px;

      color: black;
      text-decoration: none;
      margin-left: 10px;
    }
  }
}
