.admin-login {
  .title {
    text-align: center;
    margin-bottom: 50px;

  }

  .admin-login-form {

    .main-col {
      // margin: 0 auto;
      align-items: center;
      justify-items: center;
      justify-content: center;
      margin: auto;

      .form-container {
        font-family: "Overpass", sans-serif;
        // display: flex;


        .form-horizontal {
          // background: linear-gradient(to right, #202926 49%, #15201c 50%);
          background-color:$first-color;
          width: 300px;
          height: 300px;
          // padding: 90px 55px;  
          padding: 55px 40px;
          margin: 0 auto;
          border-radius: 15px;
          color: #333333;
          font-weight: 600;
          border: #cccccc solid 1px;
          // position: relative;
          border-radius: 150px;


          .form-container {
            .title {
              color: #838585;
              font-family: "Teko", sans-serif;
              font-size: 35px;
              font-weight: 400;
              text-align: center;
              text-transform: uppercase;
              letter-spacing: 0.5px;
              margin: 0 0 10px 0;
            }

          }
        }
      }
    }

    .form-horizontal {

      .label {
        height: 15px;
        font-size: 0.9rem;
      }

      .form-group {
        background-color: white;
        font-size: 0;
        margin: 0 0 15px;
        border: 1px solid #838585;
        border-radius: 3px;
      }

      .input-icon {
        color: #838585;
        font-size: 18px;
        text-align: center;
        line-height: 38px;
        height: 35px;
        width: 40px;
        vertical-align: top;
        display: inline-block;
      }

      .form-control {
        color: #333333;
        font-weight: 500;
        background-color: transparent;
        font-size: 0.7rem;
        letter-spacing: 1px;
        width: calc(100% - 55px);
        height: 35px;
        padding: 2px 10px 0 0;
        box-shadow: none;
        border: none;
        border-radius: 0;
        display: inline-block;
        transition: all 0.3s;
      }

      .form-control {
        &:focus {
          box-shadow: none;
          border: none;
          color: #838585;
          background-color: transparent;
        }
      }

      .btn {
        width: 85px;
        font-weight: 700;
        margin: 40px 10px 15px 0;
      }
    }

    .form-horizontal .form-horizontal .form-control .form-horizontal .form-control::placeholder {
      color: #838585;
      font-size: 13px;
      text-transform: capitalize;
    }

    /*Forgor password*/
    .form-horizontal {
      position: relative;

      .forgot-pass {
        color: #999;
        font-size: 10px;
        text-align: left;
        // width: calc(100% - 125px);
        display: block;

        a {
          color: #999;
          transition: all 0.3s ease;

          &:hover {
            color: #555;
            text-decoration: underline;
          }
        }
      }

      .signin {
        position: absolute;
        right: 30px;
        top: 170px;
      }
    }

    @media only screen and (max-width: 379px) {
      .form-container .form-horizontal {
        width: 100%;
      }
    }
  }
}

