.voucher{
    .voucher-wrapper{
        display: flex;
        width: 320px;
        border-radius: 8px;
        background-color: $fourth-color;
        // background-color: rgba(237, 229, 229, 0.451);
        margin-bottom: 10px;
        padding: 15px;
        .icon-section{
            width: 58px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            .discount{
                margin-bottom: 0;
            }
            .tags-icon{
                color: black;
                margin-left: 5px;
            }
        }
        .info-section{
            width: 174px;
            padding-left: 15px;
            .voucher-name{
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 5px;
            }
            .valid-date{
                font-size: 10px;
                font-weight: 400;
                margin-bottom: 5px;
            }
            .min-spend{
                font-size: 12px;
                font-weight: 500;
                margin-bottom: 0;
            }
        
          
        }
        .delete-expired-section{
            width: 58px;
            display: flex;
            justify-content: center;
            align-items: center;
            .delete-button{
                border: none;
                background-color: transparent!important;

                .trash-icon{
                    color: black;
                }
            }
            .expired-text{
                font-size: 13px;
            }
            .claim-now-text{
                text-align: center;
                font-weight: 600;
                color: $third-color;
                cursor: pointer;
                font-size: 12px;

            }
            .claimed-wrapper{
                height: 45px;
                width: 45px;
                border-radius: 50%;
                border: 2px solid white;
                transform: rotate(-30deg);
                position: relative;
                
                .claimed-text{
                    font-size: 14px;
                    position: absolute;
                    top: 12px;
                    left: -6px;
                }
                .redeemed-text{
                    font-size: 14px;
                    position: absolute;
                    top: 12px;
                    left: -12px;
                }
            }
            .not-applicable-text{
                text-align: center;
                font-size: 12px;
                font-weight: 600;
                color :red;
            }
        }
    }
}
.order-payment-voucher{
    .voucher-wrapper{
        display: flex;
        width: 280px;
        border-radius: 8px;;
        background-color: $fourth-color;
        margin-bottom: 10px;
        padding: 10px;
        .icon-section{
            width: 54px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            .discount{
                margin-bottom: 0;
            }
            .tags-icon{
                color: black;
                margin-left: 5px;
            }
        }
        .info-section{
            width: 150px;
            padding-left: 15px;
            .voucher-name{
                font-size: 12px;
                font-weight: 600;
                margin-bottom: 5px;
            }
            .valid-date{
                font-size: 8px;
                font-weight: 400;
                margin-bottom: 5px;
            }
            .min-spend{
                font-size: 10px;
                font-weight: 500;
                margin-bottom: 0;
            }
        
          
        }
        .delete-expired-section{
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
      
            .claimed-wrapper{
                height: 40px;
                width: 40px;
                border-radius: 50%;
                border: 2px solid white;
                transform: rotate(-30deg);
                position: relative;
                
        
                .redeemed-text{
                    font-size: 11px;
                    position: absolute;
                    top: 12px;
                    left: -10px;
                }
            }
          
        }
    }
}
