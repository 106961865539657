.single-product-page {

  position: relative;
  margin-bottom: 50px;

  .dashboard-spinner {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 600px;
  }

  .wrapper {
    line-height: 2.5rem;
    @media (max-width: 480px) {
      line-height: 2.2rem;

    }

    /********   Product images carousel   *******/
    .carousel {
      width: 100%;
      height: 100%;
      overflow: hidden;

      .all-dots {
        display: flex;
        flex-direction: column;
        width: 20% !important;
        height: 86% !important;
        position: absolute;
        top: 0;
        left: 16px;
        bottom: 0;
        overflow-y: scroll;

        button {
          width: 70%;
          border: 1px solid #ccc;
          padding: 0 !important;
          border-radius: 5px;
          margin: 8px auto;
          opacity: 0.4;
        }

        .carousel__dot--selected {
          border-color: #e77600;
          outline: none;
          box-shadow: 0 0 3px 2px rgba(228, 121, 17, 0.5);
          border-color: #acc;
          opacity: 1;
        }
      }

      /***** the slider itself ******/
      .carousel__slider {
        width: 80% !important;
        margin-left: auto;
        z-index: 100 !important;
        background-color: #fff;

        .down {
          margin-left: 3%;
          color: #555 !important;
          font-size: 13px;
          line-height: 19px;
          font-weight: 600;
        }

        .carousel__image {
          background-size: contain !important;
          background-repeat: no-repeat;
          background-position: center center;
        }

        .carousel__back-button {
          height: 50px;
          width: 50px;
          line-height: 50px;
          background-color: unset;
          border: none;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          right: 96%;
        }

        .carousel__next-button {
          height: 50px;
          width: 50px;
          line-height: 50px;
          background-color: unset;
          border: none;
          border-radius: 50%;
          position: absolute;
          top: 50%;
          right: -6%;
        }
      }
    }

   .wrapper-right{
     display: flex;
     justify-content: flex-start;
     align-items: center;
     .wrapper-right-container{
       width: 100%;
       
    }
   }

    /********   Product details  *******/
    .product-name {
      font-size: 18px;
      font-weight: 600;
      // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
      //   Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      color: #222;
      text-decoration: underline;
    }
    .discount-tag{
      position: absolute;
      top: -100px;
      z-index: 100;
      color: red;
      font-weight: 600;
    }

    .product-info {
      font-size: 13px;
      font-weight: 600;
      color: #333333;
      padding-bottom: 0;
      .ori-price{
        color: red;
        display: inline-block;
        font-size: 10px;
        text-decoration-line: line-through;
        margin: 0 5px;
      }
      .discounted-price{
        display: inline-block;
        margin: 0;
      }
    }
    .product-info-sm-wrapper{
      padding: 0;    
      margin: 0;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }

    .product-stock {
      font-size: 13px;
      font-weight: 600;
      color: red;
    }

    .order-quantity {
      span {
        margin-right: 5%;
        font-size: 13px;
        font-weight: 600;
        color: #333333;
      }
    }

    .custom-select {
      width: 4.5rem;
      font-size: 13px;
      font-weight: 600; 
      text-align: center;
      border:2px solid $second-color;
      &:focus {
        box-shadow: none;
      } 
      @media (max-width: 776px) {
        font-size: 12px;
  
      }
    }
    .add-to-cart{
      font-size: 14px;

      @media (max-width: 776px) {
        font-size: 12px;
  
      }
    }

    .product-category {
      font-size: 13px;
      font-weight: 600;
      color: #333333;
      margin-bottom: 100x;
      @media (max-width: 776px) {
        font-size: 14px;
  
      }

      a {
        text-decoration: none;
        color: $third-color;

        &:hover {
          color:#2e3d7f;
        }
      }
    }
  }

  .product-desc {
    font-size: 13px;
    font-weight: 600;
    color: #777777;
    border: 2px solid $second-color;
    padding-left: 12px;
    
    .description-content {
      padding: 20px 0;
      h6 {
        font-size: 13px;
        font-weight: 400;
        color: #333333;
        display:block;
        line-height: 25px;
        padding-left: 20px;
        padding-right: 20px;
        margin: 0;
        @media (max-width: 776px) {
          padding-left: 5px;
          padding-right: 5px;  
        }
      }
    }
  }
}    

// used for single product and evenos indicators
.description-btn {
  border: 1px solid lavender;
  padding: 10px 30px;
  margin: 5px 0;
  height: 39px;
  display: inline-block;
  color: #778da9;
  font-size: 13px;
  font-weight: 700;
  margin-left: 5px;
  &:hover {
    text-decoration: none;
  }
}