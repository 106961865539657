.orders-history {

  .header {
    margin-bottom: 25px;
    padding-left: 16px;
    padding-right: 16px;

    h1 {
      font-size: 2rem;
    }
  }

  .box {
    justify-content: center;

    .single-order {
      width: 100vw;
      margin: 0 15px 15px 15px;
      border: 2px solid $second-color;
      border-bottom: none;
      border-radius: 5px;

      @media (min-width: 481px) {
        width: 85vw;
      }

      @media (min-width: 768px) {
        width: 65vw;
      }

      @media (min-width: 992px) {
        width: 55vw;
      }

      .single-order-heading {
        overflow: hidden;
        padding: 5px 0;
        width: 100%;
        background-color: $first-color;
        margin: 0;
        border-radius: 5px 5px 0 0;
        border-bottom: 2px solid $second-color;
        font-size: 0.8rem;
        font-weight: 600;
        color: #333333;

        @media (max-width: 480px) {
          font-size: 0.65rem;
        }
      }

      /***** Single item *****/
      .single-order-item {
        width: 100%;
        padding: 10px 0 5px;
        margin: 0;
        border-bottom: 2px solid $second-color;

        .wrapper {
          text-align: center;

          img {
            width: 40vh;
            border: none;
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);

            @media (min-width: 480px) {
              width: 170px;
              font-size: 14px;
            }
          }

          a {
            font-size: 19px;
            color: #555;
            text-decoration: underline;
            font-weight: 600;

            &:hover {
              cursor: pointer;
              color: #777777;
            }
          }
        }

        .productName {
          margin-top: 50px;
          font-size: 12px;
          text-decoration: none;
          font-weight: 700;
          color: $third-color;
          @media (max-width: 480px) {
            margin-top: 40px;
          }
        }

        .quantity,
        .price,
        .courier,
        .tracking {
          font-weight: 700;
          font-size: 0.8rem;

          @media (max-width: 480px) {
            font-size: 0.65rem;
          }


          span {
            font-weight: 500;
            color: #555;
          }
        }
        .order-review-link {
          color: #778da9;
          font-size: 12px;
          font-weight: 600;
          &:hover {
            text-decoration: none;
            color: #555555;
          }
        }
      }

      .invoice {
        margin: auto;
        margin-top: 10px;

        .invoice-btn {
          position: relative;
          width: 120px;
          height: 30px;
          font-weight: 600;
          font-size: 12px;
          border-radius: 25px;
          border: 2px solid $third-color;

        }
      }
    }
  }
}