.order-payment {
  padding-bottom: 10px;
  font-size: 12px;
  display:grid;
  justify-content: center;

  .order-payment-wrapper {
    width: 90vw;
    height:200px;
    // padding:10px;
    // border: 2px solid $second-color;

    @media (min-width: 481px) {
      width: 85vw;
      // padding:20px;
    }

    @media (min-width: 768px) {
      width: 65vw;
      // padding:30px;
    }

    @media (min-width: 992px) {
      width: 55vw;
      // padding:30px;
    }
    .cart-table {
      text-align: center;
      font-size: 12px;
  
      @media (max-width: 776px) {
        font-size: 11px;
  
        th {
          padding: 8px 5px 8px;
        }
      }
    }
  
    .data-table {
      text-align: center;
  
      td {
        font-size: 12px;
  
        @media (max-width: 776px) {
          padding: 10px 0 10px;
          font-size: 11px;
  
        }
      }
    }
  
    .total-price {
      font-weight: 600;
      font-size: 13px;
      span{
        font-weight: 400;

      }
      @media (max-width: 776px) {
        font-size: 10px;
      }
    }
  }

  .payment-result-wrapper {
    width: 90vw;
    // height:165px;
    margin-top: 15px;
    padding:20px;
    border: 2px solid $second-color;
    border-radius: 15px;
    // background-color: $first-color;

    @media (min-width: 481px) {
      width: 85vw;
    }

    @media (min-width: 768px) {
      width: 65vw;
      padding:30px;
    }

    @media (min-width: 992px) {
      width: 55vw;
      padding:30px;
    }
  }

  Button {
    font-size: 15px;

    @media (max-width: 776px) {
      font-size: 12px;
    }
  }
  .add-category-form{
    .form-group{
      .address{
        height:90px;

      }
    }
 
  }
  .order-link {
    color: #778da9;
    font-size: 15px;
    font-weight: 600;
    &:hover {
      text-decoration: none;
      color: #555555;
    }
  }
}