.dashboard-settings {
  margin-top: 140px;
  margin-left: -40px;
  background-color:$first-color;
  z-index: 999;
  border-right: 5px solid white;
  border-top: 5px solid white;

  @media (min-width: 992px) {
    margin-top: 145px;
    position: fixed;
    width: 17vw;
    height: 100vh;
  }

  .category {
    margin-top: 0px;
    padding: 15px 0 15px;
    list-style: none;

    h1 {
      color: $third-color;
      display: inline;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 10px;

    }

    hr {
      position: relative;
      left: -18px;
      width: 100vw;
      border: 1px solid white;
      @media (min-width: 992px) {
        width: 17vw;
      }
    }

    .icon {
      position: relative;
      bottom: 2px;
      color: $third-color;
      margin-right: 10px;
      font-size: 24px;
    }

    ul {
      margin-left: -15px;
      border-top: 2px solid white;

      li {
        height: 37px;
        list-style: none;
        margin-left: -40px;
        list-style: none;
        font-size: 13px;

        a {
          display: inline-block;
          width: 100%;
          height: 100%;
          color: $third-color;
          padding-left: 15px;
          padding-top: 10px;
          text-decoration: none;
          font-weight: 600;

          &:hover {
            background-color: $second-color;
            color:black;
          }

        }
      }
    }
  }
}