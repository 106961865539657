@import "./settings-sidebar.scss";
@import "./account-settings.scss";
@import "./orders-history.scss";
@import "./addresses-list.scss";
@import "./BV.scss";
@import "./userFamily.scss";
@import "./memorandum.scss";
@import "./gallery.scss";
@import "./userVoucher.scss";

// for EditAccountForm, ChangePasswordForm, EditAddressForm and AddAddressForm
.formik {
  padding-top: 140px;
  padding-bottom: 10px;

  @media (min-width: 992px) {
    padding-top: 155px;
  }
}
// ---------------FOR ACCOUNT SETTING PAGE (NOT FOR DASHBOARD PAGE) per page scss-----------

.member-per-page-col {
  position: relative;
  height: 50px;

  .per-page {
    position: absolute;
    margin-bottom: 10px;
    right: 0;
    font-weight: 500;

    .custom-select {
      position: absolute;
      width: 170px;
      height:30px;
      right: 95px;
      text-align: center;
      font-size: 12px;
      font-weight: 700;
      border:1px solid $second-color;
      box-shadow: none;
      @media (max-width: 480px) {
        right: 80px;
      }
      @media (max-width: 776px) {
        width:20vw;
      }
    }

    span {
      margin-top: 5px;
      position: absolute;
      right: 20px;
      font-size: 14px;
      width: 85px;
      text-align: right;
      @media (max-width: 480px) {
        right: 5px;

      }
    }
  }
}

// --------------------------------------

.spinner {
  text-align: center;
  margin-top: 30px;
}