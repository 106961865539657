.contact-us {
  text-align: center;

  .title {
    text-align: center;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 5vh;
    @media (max-width: 767px) {
      font-size: 2rem;
    }
    }

  .contact-form {
    position: relative;

    @media (max-width: 992px) {
      padding-left: 20vw;
      padding-right: 20vw;
    }
    @media (max-width: 480px) {
      padding-left: 10vw;
      padding-right: 10vw;
    }

    .single-input {
      border: 2px solid $second-color;
      margin-bottom: 10px;
      height: 35px;
      @media (min-width: 991px) {
        margin-right:10px;

      }

      .icon {
        top: 9px;
        margin-right: 6px;
        margin-left: 6px;
        width: 20px;
        position: relative;
        color:$third-color;
      }

      input {
        font-size: 12px;
        top: 7px;
        position: relative;
        border: none;
        width: 85%;
        margin-bottom: 10px;
        font-weight: 700;

        &:focus {
          outline: none;
        }
      }
    }

    .text-area-input {
      border: 2px solid $second-color;
      margin-bottom: 10px;
      height:125px;

      .icon {
        top: 9px;
        width: 20px;
        margin-right: 6px;
        margin-left: 6px;
        position: relative;
        color:$third-color;

      }

      .text-area {
        font-size: 12px;
        top: 7px;
        position: relative;
        border: none;
        width: 85%;
        margin-bottom: 10px;
        font-weight: 700;

        &:focus {
          outline: none;
        }

      }
    }

  }
  .contact-logo-wrapper{
    text-align: left;
    padding-left: 2px;
    margin-top: 25px;
    
    @media (max-width: 992px) {
      padding-left: 20vw;
      padding-right: 20vw;
    }
    @media (max-width: 480px) {
      padding-left: 10vw;
      padding-right: 10vw;
    }
   
    .contact-item {
      height: 30px;
      padding: 0;
      margin: 0;
      // display: inline-block;

      img {
        height:30px;
        width: 30px;
        border-radius: 6px;
        margin-bottom: 20px;
        display: inline-block;

      }
      p{
        position: relative;
        top: -7px;
        left:10px;
        font-size: 13px;
        font-weight: 600;
        display: inline-block;
        color:black;
      }
    }
  
  }

  
  iframe{
    margin-top: 10px;
    width: 50vw;
    height: 400px;
    border: 3px solid $second-color;
    @media (max-width: 992px) {
      width: 60vw;
    }
    @media (max-width: 480px) {
      width: 80vw;
    }
   }


}