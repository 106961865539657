.about-us {
  text-align: center;
  // justify-content: center;
  // align-items: center;

  .title {
    text-align: center;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 5vh;
    @media (max-width: 767px) {
      font-size: 2rem;
    }
  }
  .content-wrapper {
    padding: 0 15vw;
    @media (max-width: 950px) {
      padding: 0 5vw;
    }
    h1 {
      color: $third-color;
      font-size: 25px;

      @media (max-width: 950px) {
        font-size: 20px;
      }
    }
    p {
      font-size: 13px;
      color: #191717;
      padding: 30px 40px;
      border-radius: 15px;
      background-color:$fourth-color;
      
      @media (max-width: 767px) {
        font-size: 12px;
        padding: 20px 30px;
      }
      @media (max-width: 481px) {
        font-size: 12px;
        padding: 20px 20px;
      }
    }
  }
}
