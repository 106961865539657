.main-footer {
  padding: 0 !important;
  margin: 0 !important;
  position: relative;
  bottom: -8vh;
  text-align: left;
  background-color:$first-color;
  width:100vw;

  @media (max-width: 991px) {
    text-align: center;


  }

  .footer {
    color: black;
    padding-top: 2.5vh;
    height: 11vh;
    text-align: center;

    h1 {
      text-align: center;
      font-size: 0.8rem;
      font-weight: 700;

    }
    a{
      font-weight: 600;
      font-size: 0.8rem;
      color:#333333;
      &:hover{
        text-decoration: none;
        color:gray;
      }
    }

  }

  .footer-logout {
    color: black;
    font-weight: 600;
    font-size: 0.8rem;

    .footer-bottom {
      padding-top: 18px;
      margin: auto;
      justify-content: center;

      h1 {
        width: 95vw;
        text-align: center;
        font-weight: 700;
        font-size: 0.8rem;
        padding-bottom: 15px;

      }
    }
    .footer-box-wrapper{
      margin: 0;
      .footer-box-left {
        padding: 20px 14vw 3vh;
        border-right: 2px solid white;

        img{
          position: relative;
          // left:-6px;
          height:50px;
          width: 50px;
          filter: brightness(70%);
          @media (max-width: 991px) {
            left:0;

          }
        }

        @media (max-width: 991px) {
          border-right:none;
          padding: 15px 14vw 3vh;
        }
        p{
          margin-bottom: 3px;
        }
      }
  
      .footer-box-right {
        padding: 23px 14vw 15px;

        @media (max-width: 991px) {
          padding: 0 14vw 15px;
        }
        p{
          margin-bottom: 3px;
        }
  
        .logo-wrapper {
          margin: 5px 0 8px;
          display: flex;
  
          @media (max-width: 991px) {
            justify-content: center;
            margin: 0 0 3px;

          }
  
          .contact-item {
            position: relative;
            top: 10px;
            left:-1px;
            
            img {
              max-width:50px;
              max-height:35px;
              width: auto;
              height: auto;
              border-radius: 6px;
              margin-right:5px ;
              transform: translateY(-25%);
              @media (max-width: 1180px) {
                margin-right:3px;
                max-width:45px;
              }
              @media (max-width: 1090px) {
                margin-right:3px;
                max-width:42px;
              }
              @media (max-width: 991px) {
                max-width:55px;
              }
              @media (max-width: 460px) {
                margin-right:3px;
                max-width:45px;
              }
              @media (max-width: 370px) {
                margin-right:3px;
                max-width:40px;
              }
            }
          }
        }
  
  
      }
      a{
        color:black;
        &:hover{
          text-decoration: none;
          color:gray;
        }
      }
    }
    


  }
}