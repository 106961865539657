/* General Style */

// change all tables colors
.table-dark {
  background-color: #123 !important;
}

.bootstrap-table{
  font-size: 13px;
  font-weight: 400;
}
// general spinner style
.general-spinner {
  text-align: center;
  padding-top: 10%;
}

// general pagination
.general-pagination {
  position: relative;
  bottom: 0;
  width: 90%;
  margin: 0 auto;
  padding: 30px 0;

  .MuiPagination-root {
    text-align: center;

    ul {
      display: inline-flex;
      display: -webkit-inline-flex;
    }
  }
}

/* Page body */
.page-body {

  min-height: 87vh;
  margin-right: 0;
  margin-left: 0;
  width: 100%;
  background-color: #fff;
}

// test to remove the bottom scrollbar in chrome but seems like not working

/* Hide scrollbar for Chrome, Safari and Opera */
.App::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.App {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
//------------------------------------------------------------------------------