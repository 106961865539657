.white-div{
  @media (min-width: 992px) {
    position: fixed;
    z-index: 990;
    height:133px;
    width:100vw;
    background-color: white;
    }
}

.main-navbar {
  height: 60px;
  display: flex;
  position: relative;
  padding: 0 !important;
  margin-bottom: 50px;
  z-index: 1000;
  background-color:$first-color ;
  justify-content: center;

  @media (min-width: 992px) {
  position: fixed;
  width:100vw;
  }

  .menu-btn {
    position: absolute;
    z-index: 7;
    left: 1rem;
    top: 1rem;
    height: 2rem;
    width: 28px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;

    .burger {
      position: absolute;
      background-color: $third-color;
      right: 0;
      top: 14px;
      width: 28px;
      height: 2.5px;
      // background: set-text-color(#272727);
      transition: all 0.5s ease-in-out;

      &:hover {
        background-color: #777777;
      }

      &::before {
        content: '';
        position: absolute;
        background-color: $third-color;
        top: -8px;
        width: 28px;
        height: 2.5px;
        // background: set-text-color(#272727);
        transition: all 0.5s ease-in-out;
      }

      &::after {
        content: '';
        position: absolute;
        background-color: $third-color;
        top: 8px;
        width: 28px;
        height: 2.5px;
        // background: set-text-color(#272727);
        transition: all 0.5s ease-in-out;
      }

      &.open {
        transform: rotate(360deg);
        background: transparent;

        &::before {
          transform: rotate(45deg) translate(5px, 8px);
        }

        &::after {
          width: 28px;
          transform: rotate(-45deg) translate(3px, -7px);
        }
      }
    }
  }

  .nav {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    z-index: 1;

    &.open {
      visibility: visible;
    }

    .menu-nav {
      display: flex;
      flex-flow: column wrap;
      align-items: center;
      justify-content: center;
      height: 100vh;
      width: 100vw;
      overflow: hidden;
      background: white;
      color: #eeeeee;
      list-style-type: none;
      padding-right: 2rem;
      transform: translateX(-100%);
      transition: all 0.5s ease-in-out;
      padding-bottom: 10vh;

      @media (min-width: 992px) {
        width: 30vw;
      }

      &.open {
        transform: translateX(0);
      }

      .item {

        &.active>a {
          color: #777777;
        }
      }

      .link {
        color: black;
        display: inline-block;
        font-size: 1.3rem;
        // text-transform: uppercase;
        padding: 1rem 0;
        font-weight: 300;
        // transition: all 0.5s ease-in-out;

        &:hover {
          color: #777777;
        }
      }

      // this has to be put in-line with .item
      @for $i from 1 through 6 {
        .item:nth-child(#{$i}) {
          transition-delay: ($i * 0.1s) + 0.15s;
        }
      }
    }
  }



  @media (min-width: 992px) {

    .nav {
      .menu-nav {
        .item {
          display: inline;
        }

        .link {
          font-size: 1.5rem;
        }
      }
    }
  }

  /** Login and signup **/
  .navbar-login {
    position: absolute;
    top: 17px;
    right: .7rem;
    display: flex;
    text-align: right;

    .nav-login-link {
      margin: 2px 3px;
      color: $third-color;
      font-size: .9rem;
      // transition: all 0.5s ease-in-out;
      font-weight: 500;

      &:hover {
        color:#67A3D9 ;
      }
    }
    p {
      display: block;
      margin: 2px 3px;
      color: black;
      font-size: .9rem;
      font-weight: 500;
    }
  }

  .logo-login {
    position: relative;

    @media (min-width: 992px) {
      p {
        position: relative;
        font-size: 1rem;
        font-weight: 600;
        width: 80px;
        top: 56px;
        right: 20px;
      }
    }

    img {
      position: relative;
      height: 60px;
      width: 60px;
      // filter: brightness(70%);
      // top: 5px;
      left: 32px;
    }

    a {
      &:hover {
        color: black;
        text-decoration: none;
      }

      p {
        position: relative;
        font-size: 0.6rem;
        font-weight: 600;
        width: 80px;
        top: 23px;
        right: 18px;
        color:$third-color;

        @media (min-width: 992px) {
          position: relative;
          font-size: 0.8rem;
          font-weight: 600;
          width: 100px;
          top: 20px;
          right: 28px;
        }
      }
    }
  }

  .logo-logout {
    position: relative;

    img {
      position: relative;
      // top: 5px;
      height: 60px;
      width: 60px;
      // filter: brightness(70%);
    }
  }

  /*cart icon*/
  .cart-box {
    position: absolute;
    width: 40px;
    text-align: center;
    top: 20px;
    right: 1rem;

    .cart-icon {
      font-size: 1.6rem;
      color: $third-color;

      &:hover {
        color: #011f4b;
      }
    }
  }

  .counter {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 15px;
    right: 15px;
    color: $third-color;
  }

  .function-bar {
    position: absolute;
    top: 66px;
    width: 98vw;

    .function-item-wrapper{
      padding:0 1px;
      .function-item {
        width:100%;
        background-color:white;
        border: 2px solid $second-color;
        width: 100%;
        padding: 0;
        margin-bottom: 1px;
  
        &:hover {
          a {
            // margin-left: 1px;
            background-color: $first-color;
          }
        }
  
        .dropdown-item {
          font-size: 14px;
          padding-left: 10px;
          color:$third-color;
        }
  
        i {
          margin-right: 10px;
          width: 16px;
          color: $third-color;
          font-size: 16px;
        }
  
        // specially for FontAwesomeIcon
        .icon {
          position: relative;
          color: $third-color;
          margin-right: 10px;
          width: 16px;
          font-size: 16px;
        }
         // specially for mui-icon
         .mui-icon {
          position: relative;
          color: $third-color;
          margin-right: 7px;
          top: -2px;
          left: -2px;
          width: 19px;
          font-size: 19px;
        }
      }
    }    
  }
}