.addresses-list {
  .header {
    margin-bottom: 25px;
    padding-left: 16px;
    padding-right: 16px;

    h1{
      font-size: 2rem;
    }
  }
  .address-wrapper{
    @media (min-width: 992px) {
      padding: 0 15vh;

    }
/*****  Add Address button *******/
.add-address {
  display: block;
  text-align: center;
  padding-top: 5vh;
  width: 90%;
  height: 170px;
  border: 2px dashed $second-color;
  border-radius: 10px;
  cursor: pointer;
  margin: 0 auto 20px auto;
  background-color:$fourth-color;

  &:hover {
    text-decoration: none;
  }

  i {
    font-size: 3rem;
    color: $third-color;
  }

  span {
    font-size: 2rem;
    color: #333333;
    text-decoration: none;
  }

}

/****** One Address *******/
.one-address {
  margin-top: 20px;

  &:nth-child(2) {
    margin-top: 0;
  }

  .wrapper {
    height: 170px;
    width: 90%;
    border-radius: 10px;
    border: 2px solid $second-color;
    padding: 15px 25px;
    margin: 0 auto;
    line-height: 20px;
    font-size: 12px;

    span {
      display: block;
      color: #333333;
    }

    .name {
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 3px;
    }

    .edit {
      a {
        color: #0066c0;
        font-weight: 500;
      }

      p {
        color: #0066c0;
        display: inline;
        cursor: pointer;
        font-weight: 500;

        &:hover {
          text-decoration: underline;
          color: red;
        }
      }
    }
  }
}
  }
}