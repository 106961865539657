.indicator-box-row {
  text-align: center;
  padding-left: 11px;
  padding-right: 11px;
  display: flex;
  justify-content: center;

  .indicator-box {
    height: 200px;
    padding: 5px;

    div {
      background-color: #eeeeee;
      height: 180px;
      border-radius: 5px;
      padding: 40px;
      color: #333333;
      border: 2px solid #9a9a9a;

      p {
        font-size: 40px;
        font-weight: 700;
      }
    }
  }
}

.users-permissions {
  select {
    width: 70%;
  }
  i {
    font-size: 1.4rem;
  }

  table {
    .fa-truck,
    .fa-check {
      color: rgb(1, 175, 1);
    }
  }

  .fa-ban {
    color: red;
  }
}

.registration-fee{
  font-size: 11px;
  margin: 0;
  padding: 0;
  color:#195275;
  font-weight: 600;
}
