.cart {
  padding-bottom: 10px;
  font-size: 12px;


  .total-price {
    font-weight: 600;
    font-size: 13px;
    span{
      font-weight: 400;

    }
    @media (max-width: 776px) {
      font-size: 10px;
    }
  }

  Button {
    font-size: 15px;

    @media (max-width: 776px) {
      font-size: 12px;

    }
  }
  .cart-table {
    margin-top: 25px;
    text-align: center;
    font-size: 12px;

    @media (max-width: 776px) {
      font-size: 9px;

      th {
        padding: 8px 5px 8px;
      }
    }
  }

  .data-table {
    text-align: center;

    td {
      font-size: 12px;

      .custom-select {
        font-size: 12px;
        border:1px solid $second-color;
        &:focus {
          box-shadow: none;
        }      
      }
      .select-checkbox{
        width:15px;
        height:15px
      }
      .delete-wrapper{
        padding-top: 15px;
        Button {  
          padding: 0;
          width: 25px;
          height: 25px;
        }
      }

      @media (max-width: 776px) {
        padding: 10px 0 10px;
        font-size: 9px;

        .custom-select {
          font-size: 10px;
        }

        
      }
    }
  }
}