.signup-form {
  .main-col {
    margin: 0 auto;
    height: 1340px;
    font-family: "Overpass", sans-serif;

      .form-horizontal {
        // background: linear-gradient(to right, #202926 49%, #15201c 50%);
        background-color: white;
        width: 100%;
        height: 1340px;
        padding: 40px 30px;
        border-radius: 15px;
        color: $third-color;
        border: $second-color solid 2px;
        font-weight: 600;
      }
      p{
        font-size: 12px;
      }
      .label {
        height: 15px;
        font-size: 0.9rem;
      }
  
      .form-group {
        font-size: 0;
        border-radius: 3px;
        border: 2px solid $second-color;
  
        &:hover{
          border: rgba(95, 181, 216, 0.775) solid 2px;
        }
      }
  
      .form-control {
        color: #000;
        font-weight: 500;  
        background-color: transparent;
        font-size: 0.7rem;
        letter-spacing: 1px;
        width: calc(100% - 5px);
        height: 30px;
        padding: 2px 0 0 8px;
        box-shadow: none;
        border: none;
        border-radius: 0;
        display: inline-block;
        transition: all 0.3s;
  
        // &::placeholder {
        //   color: #333333;
        // }
    
      }
  
      .btn {
        position: absolute;
        margin-top: 35px;
        right: 46px;
        width: 85px;
      }
    
  }

  // .form-horizontal .form-horizontal .form-control .form-horizontal .form-control::placeholder {
  //   color: #838585;
  //   font-size: 13px;
  //   text-transform: capitalize;
  // }

  /*Forgot password*/
  .form-horizontal {
    .forgot-pass {
      color: #333333;
      font-size: 10px;
      text-align: left;
      width: calc(100% - 125px);
      display: inline-block;
      vertical-align: top;

      a {
        color: #333333;
        transition: all 0.3s ease;

        &:hover {
          color: #9a9a9a;
          text-decoration: underline;
        }
      }
    }
  }

  @media only screen and (max-width: 379px) {
    .form-container .form-horizontal {
      width: 100%;
    }
  }
}