.memorandum {
  .header {
    margin-bottom: 25px;
    padding-left: 16px;
    padding-right: 16px;

    h1 {
      font-size: 2rem;
    }
  }

  .single-memo {
    .single-memo-wrapper{
      padding: 20px;
      margin-bottom: 30px;
      background-color:$fourth-color;
      border-radius: 15px;
      @media (min-width: 481px) {
        margin: 0 5vw 30px;
        }
  
      @media (min-width: 768px) {
        margin: 0 10vw 30px;
        padding: 25px;

       }
  
      @media (min-width: 992px) {
        margin: 0 15vw 30px;
        padding: 30px;

      } 
      .title-date-wrapper{
        margin-bottom: 20px;

        .memo-title{
          text-align: left;
          @media (max-width: 480px) {
            h4{
              font-size: 20px;

            }
           }

        }
        .memo-date{
          padding: 10px 30px 0 0;
          text-align: right;
          font-size: 15px;

        }
      }
      .memo-to{
        margin-bottom: 20px;
        font-size: 25px;
      }
      p{
        font-size: 13px;
      }
    }
  }

  
}