.account-settings {
  .header {
    margin-bottom: 25px;
    padding-left: 16px;
    padding-right: 16px;

    h1 {
      font-size: 2rem;
    }
  }

  .account-information {
    padding-left: 7px;
    padding-right: 7px;
    margin: 0 0vw;
    // padding-bottom: 5px;
    display: block;
    h5{
      color:#778da9;
      font-weight: 600;
    }
    @media (min-width: 481px) {
      margin: 0 5vw;
      }

    @media (min-width: 768px) {
      margin: 0 10vw;
     }

    @media (min-width: 992px) {
      margin: 0 15vw;
    }
    .edit-button-1{
      margin-inline-end: 10px;
      font-weight: 400;
      color: #555555;
      button {
        font-size: 14px;
        padding: 6px;
      }
    }
    .edit-button-2{
      margin-inline-end: 10px;
      font-weight: 400;
      color: #555555;
      button {
        font-size: 14px;
        padding: 6px;
      }
    }
    .add-category-form{
      .form-group{
        .address{
          @media (max-width: 991px) {
            height:50px;
            }
            @media (max-width: 767px) {
              height:70px;
              }
          @media (max-width: 480px) {
            height:90px;
            }
        }

        .affiliate{
          @media (max-width: 417px) {
            height:48px;
            }
        }
        .affiliate-btn{
          background-color: white;
          color: black;
          width: 100px;
          text-align: center;
          display: inline;
          margin-top: 2px;
        }
      }
    }
  }
}