.featured-products {
  min-height: 400px;

  .spinner {
    text-align: center;
    margin-top: 30px;
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
    color: grey;
  }

  .product-card {
    padding: 3px;

    .card{
      border:2px solid $second-color;
      .product-card-image{
        width:100%;
        height:155px;
        display:flex;
        justify-content: center;

        .image{
          padding-top: 3px;
          width:150px;
          height:150px;
        }
      }
      .discount-tag{
        position:absolute;
        top:0;
        right:0;
        text-align: center;
  
        .icon{
          position:absolute;
          top:-8px;
          right:1px;
          width:30px;
          height:58px;
          color:yellow
        }
        p{
          position:absolute;
          top:2px;
          right:1px;
          width:30px;
          text-align: center;
          z-index: 1;
          color:red;
          font-weight: 700;
          font-size: 10px;
        }
     
      }
   
      .product-details {
        padding:14px 15px;
        .best-before {
          margin: 5px 0 5px;
          font-size: 11px;
        }
        .product-price{
          margin:0;
          font-size: 11px;
          font-weight: 700;
          color:$third-color;

          .ori-price{
            text-decoration-line:line-through;
            color: red;
            font-size: 10px;
            margin:0 5px 0 0;
          }
          .discounted-price{
            margin:0;
          }
        }
        .product-bv {
          margin:0;
          font-size: 11px;
          font-weight: 500;
  
        }
        .product-name{
          font-size: 12px;
          margin:0;
          height:28px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
          a{
            color:#778da9;

          }
 
        }
  
        .add-to-cart {
          position: relative;
          margin-top: 11px;
          margin-right: 34px;
          margin-left: 0;
  
          i {
            position: absolute;
            margin: auto;
            color: $third-color;
            bottom: 0;
          }
  
        }
        .sold-out{
          position: relative;
          margin-right: 10px;
          width:50px;

           p{
            position: absolute;
            margin: auto;
            bottom:0;
            font-size: 10px;
            font-weight: 600;
            color: red;
           }

        }
      }
    }
   

  }

}