//---------to remove filled color to input(form-control) when selecting from saved information

// @mixin box-shadow($values) {
//   -webkit-box-shadow: $values;
//   box-shadow: $values;
// }
// .form-control:-webkit-autofill {
//   @include box-shadow(0 0 0 30px white inset !important);
// }

//----------------------------------------------------------------

.add-category-form {
  width: 90%;
  margin: 15px auto 50px;
  border: 2px solid $second-color;
  border-radius: 5px;
  padding: 25px;
  @media (max-width: 481px) {
    width: 95%;
  }

  .form-group {
    label {
      color: #123;
      font-weight: 600;
      font-size: 0.9rem;
    }

    .form-control {
      border: 2px solid $second-color;

      font-size: 0.7rem;
    }
  }
}

.add-category-form-dashboard {
  width: 100%;
  margin: 25px 0 50px;
  border: 2px solid $second-color;
  border-radius: 5px;
  padding: 25px;

  .form-notes{
    font-size: 13px;
    margin: 0;
    padding: 0;
    font-weight: 500;
  }
  .form-group {

    label {
      color: #123;
      font-weight: 600;
      font-size: 0.9rem;
    }

    .form-control {
      border: 2px solid $second-color;
      font-size: 0.7rem;
    }
  }
}

.dashboard-search {
  width: 100%;
  border-radius: 5px;
  position: relative;
  top:15px;
  right: -2px;
  display: flex;
  justify-content: end;


  .form-group {
    .form-control {
      font-size: 0.7rem;
      height: 30px;
      width: 170px;
      border: 1px solid $second-color;
      text-align: center;    
      box-shadow: none;
    }
  }

  Button {
    width: 77px;
    height: 30px;
    font-size: 15px;
    font-weight: 600;
    color: white!important;
    padding: 1px 0 0 0;
    border: none;
    background-color: #00AF91!important;
    &:focus {
      box-shadow: none;
    }

  }
}

.add-category-form-popup {
  padding: 5px;

  .form-group {
    label {
      color: #123;
      font-weight: 600;
      font-size: 0.9rem;
    }

    .form-control {
      border: 2px solid $second-color;
      font-size: 0.7rem;
    }
  }
}

.edit-modal {
  .modal-dialog {
    position: relative;
    top: 50px;
  }
}

// ------for scrollbar style ---------------------------

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(179, 179, 179);
}

// ----------------------------------------------------------

.table-wrapper{
  min-height: 250px;
  box-shadow: 0 11px 11px 0 rgba(38, 112, 197, 0.16);
  border-radius: 5px;

}

.bootstrap-table{
  margin: 0!important;

  th{
    background-color:$first-color !important;
    border: 2px solid $second-color !important;
    color: black !important;
  }
  td {
    background-color: white;
    border: 2px solid $second-color !important;
  
    a {
      color: black;
  
      &:hover {
        color: #777777;
      }
    }
  }
}

.invoice-table{
  th{
    background-color:$first-color !important;
    border: 1px solid $second-color !important;
    color: black !important;
  }
  td {
    background-color: white;
    border: 1px solid $second-color !important;
  
    a {
      color: black;
  
      &:hover {
        color: #777777;
      }
    }
  }
}

.button{
  background-color:#F0F8FF !important;
  color:#195275 !important;
  // border:'3px solid #195275' !important;
}

.custom-file{
  font-size: 13px;
  font-weight: 500;
}