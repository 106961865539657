.q-and-a {

  .title {
    text-align: center;
    justify-content: center;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 5vh;
    @media (max-width: 767px) {
      font-size: 2rem;
    }
    }

  .q-and-a-body,
  .q-and-a-body-member {
    font-size: 13px;
    padding: 0 15vw;
    position: relative;
    @media (max-width: 950px) {
      padding: 0 5vw;
    }
    
    p.accordion {
      background-color:$fourth-color;
      color: #444;
      cursor: pointer;
      padding: 18px;
      width: 100%;
      text-align: left;
      border: none;
      outline: none;
      transition: 0.4s;
      margin-bottom: 10px;
    }

    /* Add a background color to the accordion if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
    p.accordion.active,
    p.accordion:hover {
      background-color: rgba(140, 179, 220, 0.426);
    }

    /* Unicode character for "plus" sign (+) */
    p.accordion:after {
      content: '\2795';
      font-size: 13px;
      color: #777;
      float: right;
      margin-left: 5px;
    }

    /* Unicode character for "minus" sign (-) */
    p.accordion.active:after {
      content: "\2796";
    }

    /* Style the element that is used for the panel class */

    div.panel {
      padding: 0 18px;
      background-color: white;
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      margin-bottom: 10px;
    }

    div.panel.show {
      opacity: 1;
      max-height: 200px;
      margin-bottom: 30px;
      /* Whatever you like, as long as its more than the height of the content (on all screen sizes) */
    }
  }
}