@import "config.scss";
@import "./account-settings/index.scss";
@import "./Cart/index.scss";
@import "./dashboard/index.scss";
@import "./home-page/index.scss";
@import "./login&signup/index.scss";
@import "not-found";
@import "main-footer";
@import "main-navbar";
@import "page-body";
@import "single-product.scss";
@import "terms-and-conditions";
@import "rating.scss";

.blankContainer {
  padding-top: 145px;
  @media (min-width: 992px) {
    padding-top: 155px;
  }
}
.logout-blankContainer{
  padding-top: 0;
  @media (min-width: 992px) {
    padding-top: 90px;
  }
 

}