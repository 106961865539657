.login-form {
  .main-col {
    // margin: 0 auto;
    align-items: center;
    justify-items: center;
    justify-content: center;
    margin: auto;

    .form-container {
      font-family: "Overpass", sans-serif; 

      .form-horizontal {
        background-color: white;
        width: 300px;
        height: 300px;
        padding: 40px 30px;
        margin: 0 auto;
        border-radius: 15px;
        color: $third-color;
        font-weight: 600;
        border: $second-color solid 2px;


        .form-container {
          .title {
            color: #838585;
            font-family: "Teko", sans-serif;
            font-size: 35px;
            font-weight: 400;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            margin: 0 0 10px 0;
          }

        }
      }
    }
  }

  .form-horizontal {

    .label{
      height:15px;
      font-size:0.9rem;
    }
    .form-group {
      font-size: 0;
      border-radius: 3px;
      border: 2px solid $second-color;

      &:hover{
        border: rgba(95, 181, 216, 0.775) solid 2px;
      }
    }

    .input-icon {
      color: #5b5d5d;
      font-size: 18px;
      text-align: center;
      line-height: 38px;
      height: 35px;
      width: 40px;
      vertical-align: top;
      display: inline-block;
    }

    .form-control {
      color: #000;
      font-weight: 500;  
      background-color: transparent;
      font-size: 0.7rem;
      letter-spacing: 1px;
      width: calc(100% - 55px);
      height: 35px;
      padding: 2px 10px 0 0;
      box-shadow: none;
      border: none;
      border-radius: 0;
      display: inline-block;
      transition: all 0.3s;
    }

    .btn {
      width: 85px;
      margin: 55px 0 15px 0;
    }
  }
  .form-horizontal
    .form-horizontal
    .form-control
    .form-horizontal
    .form-control::placeholder {
    color: #838585;
    font-size: 13px;
    text-transform: capitalize;
  }

  /*Forgot password*/
  .form-horizontal {
    position: relative;
    .forgot-pass {
      color: #333333;
      font-size: 10px;
      text-align: left;

      a {
        color: #333333;
        transition: all 0.3s ease;

        &:hover {
          color: #9a9a9a;
          text-decoration: underline;
        }
      }
    }
    .signin{
      position: absolute;
      right:30px;
      top:170px;
    }
  }

  @media only screen and (max-width: 379px) {
    .form-container .form-horizontal {
      width: 100%;
    }
  }
}

.Slide {
  position: absolute;
  color: yellow;
  min-width: 50%;
  padding: 10px;
  z-index: 500;
}

.toast {
  position: absolute;
  // color: red;
  min-width: 50%;
  padding: 10px;
  z-index: 500;
}