.home {

  .wrapper {
    margin-bottom: 40px;
    @media (min-width: 992px) {
      padding: 0 15vw 0;
      margin-bottom: 70px;

    }
  .slide{
    border-top: 1px solid $second-color;
    border-bottom: 1px solid $second-color;

  }
  }
}